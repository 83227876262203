<script>
import {layoutComputed} from "@/state/helpers";

export default {
    data() {
        return {
            settings: {
                minScrollbarLength: 60,
            },
            expandedMenus: [],
        };
    },
    mounted() {
        if (document.querySelectorAll(".navbar-nav .collapse")) {
            let collapses = document.querySelectorAll(".navbar-nav .collapse");

            collapses.forEach((collapse) => {
                // Hide sibling collapses on `show.bs.collapse`
                collapse.addEventListener("show.bs.collapse", (e) => {
                    e.stopPropagation();
                    let closestCollapse = collapse.parentElement.closest(".collapse");
                    if (closestCollapse) {
                        let siblingCollapses =
                            closestCollapse.querySelectorAll(".collapse");
                        siblingCollapses.forEach((siblingCollapse) => {
                            if (siblingCollapse.classList.contains("show")) {
                                siblingCollapse.classList.remove("show");
                            }
                        });
                    } else {
                        let getSiblings = (elem) => {
                            // Setup siblings array and get the first sibling
                            let siblings = [];
                            let sibling = elem.parentNode.firstChild;
                            // Loop through each sibling and push to the array
                            while (sibling) {
                                if (sibling.nodeType === 1 && sibling !== elem) {
                                    siblings.push(sibling);
                                }
                                sibling = sibling.nextSibling;
                            }
                            return siblings;
                        };
                        let siblings = getSiblings(collapse.parentElement);
                        siblings.forEach((item) => {
                            if (item.childNodes.length > 2)
                                item.firstElementChild.setAttribute("aria-expanded", "false");
                            let ids = item.querySelectorAll("*[id]");
                            ids.forEach((item1) => {
                                item1.classList.remove("show");
                                if (item1.childNodes.length > 2) {
                                    let val = item1.querySelectorAll("ul li a");

                                    val.forEach((subitem) => {
                                        if (subitem.hasAttribute("aria-expanded"))
                                            subitem.setAttribute("aria-expanded", "false");
                                    });
                                }
                            });
                        });
                    }
                });

                // Hide nested collapses on `hide.bs.collapse`
                collapse.addEventListener("hide.bs.collapse", (e) => {
                    e.stopPropagation();
                    let childCollapses = collapse.querySelectorAll(".collapse");
                    childCollapses.forEach((childCollapse) => {
                        childCollapse.style.display = "none";
                    });
                });
            });
        }
    },
    computed: {
        ...layoutComputed,
        getMenuCollapseId() {
            return function(menuId) {
                return `menuCollapse${menuId}`;
            }
        },
        layoutType: {
            get() {
                return this.$store ? this.$store.state.layout.layoutType : {} || 'horizontal';
            },
        },
        links: {
            get() {
                return this.$store.getters['Dashboard/DashboardGetterLinks']
            },
        },
    },

    methods: {
        onRoutechange(ele) {
            this.initActiveMenu(ele.path);
            if (document.getElementsByClassName("mm-active").length > 0) {
                const currentPosition = document.getElementsByClassName("mm-active")[0].offsetTop;
                if (currentPosition > 500)
                    if (this.$refs.isSimplebar)
                        this.$refs.isSimplebar.value.getScrollElement().scrollTop = currentPosition + 300;
            }
        },

        initActiveMenu(ele) {
            setTimeout(() => {
                if (document.querySelector("#navbar-nav")) {
                    let a = document.querySelector("#navbar-nav").querySelector('[href="' + ele + '"]');
                    if (a) {
                        a.classList.add("active");
                        let parentCollapseDiv = a.closest(".collapse.menu-dropdown");
                        if (parentCollapseDiv) {
                            parentCollapseDiv.classList.add("show");
                            parentCollapseDiv.parentElement.children[0].classList.add("active");
                            parentCollapseDiv.parentElement.children[0].setAttribute("aria-expanded", "true");
                            if (parentCollapseDiv.parentElement.closest(".collapse.menu-dropdown")) {
                                parentCollapseDiv.parentElement.closest(".collapse").classList.add("show");
                                if (parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling)
                                    parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling.classList.add("active");
                                const grandparent = parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling.parentElement.closest(".collapse");
                                if(grandparent && grandparent.previousElementSibling){
                                    grandparent.previousElementSibling.classList.add("active");
                                    grandparent.classList.add("show");
                                }
                            }
                        }
                    }
                }
            }, 0);
        },
        activateMenu() {
            const url = window.location.href.split("#")[0];
            const elements = document.querySelectorAll(".nav-link");
            const currentElement = [...elements].filter((ele) => url.includes(ele.href.split("#")[0]));
            if (currentElement.length > 0) {
                const ele = currentElement[0].getAttribute("href");
                elements.forEach((element) => {
                    element.classList.remove("active");
                });
                let a = document.querySelector("#navbar-nav").querySelector('[href="' + ele + '"]');
                if (a) {
                    a.classList.add("active");
                    document.querySelectorAll('.collapse').forEach((el) => {
                        el.classList.remove('show');
                    });
                    let parentCollapseDiv = a.closest(".collapse.menu-dropdown");
                    if (parentCollapseDiv) {
                        parentCollapseDiv.classList.add("show");
                        parentCollapseDiv.parentElement.children[0].classList.add("active");
                        parentCollapseDiv.parentElement.children[0].setAttribute("aria-expanded", "true");
                        if (parentCollapseDiv.parentElement.closest(".collapse.menu-dropdown")) {
                            parentCollapseDiv.parentElement.closest(".collapse").classList.add("show");
                            if (parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling)
                                parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling.classList.add("active");
                            const grandparent = parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling.parentElement.closest(".collapse");
                            if (grandparent && grandparent.previousElementSibling) {
                                grandparent.previousElementSibling.classList.add("active");
                                grandparent.classList.add("show");
                            }
                        }
                    }
                }
            }
        }
    },
    watch: {
        $route: {
            handler: "onRoutechange",
            immediate: true,
            deep: true,
        },
        //check if links state changed
        links: function (val) {
            if (val) {
                //
            }
        }
    },
};
</script>

<template>
    <div class="container-fluid">
        <template v-if="layoutType==='vertical'">
            <ul class="navbar-nav h-100" id="navbar-nav">
                <div v-bind:key="index" v-for="(link,index) in links">
                    <!--top title-->
                    <div class="menu-title"  style="color:#FFFFFF;font-weight: bolder">
                        <span :data-key="88"> {{ link.title }}</span>
                    </div>
                    <!--end top title-->
                    <!--BEGIN top title-->
                    <ul>
                        <li class="nav-item"  v-bind:key="index" v-for="(menu,index) in link.menus">
                            <div v-if="menu.subMenu && menu.subMenu.length > 0">
                                <a class="nav-link menu-link" :href="'#sidebar' +menu.id" :data-bs-target="'#sidebar' + menu.id" data-bs-toggle="collapse" role="button"
                                   aria-expanded="false" :aria-controls="'sidebar' + menu.id">
                                    <span style="color:#f2f2f7;">
                                        <i v-if="menu.iconType==='remix'" :class="menu.icon"></i>
                                        <i v-if="menu.iconType==='boxicons'" :class="menu.icon"></i>
                                    </span>
                                    <span data-key="t-dashboards" style="color:#f2f2f7;font-weight: bold">{{ menu.name }}</span>
                                </a>
                                <div class="collapse menu-dropdown" :id="'sidebar' + menu.id">
                                    <ul class="nav nav-sm flex-column">
                                        <li style="color:#f2f2f7;" class="nav-item" v-bind:key="i" v-for="(subMenu,i) in menu.subMenu">
                                            <div v-if="subMenu.children && subMenu.children.length > 0">
                                                <a :href="'#sidebaremail'+subMenu.id" class="nav-link" data-bs-toggle="collapse" role="button" aria-expanded="false"
                                                   :aria-controls="'sidebaremail'+subMenu.id" data-key="t-projects">
                                                    {{ subMenu.name }}
                                                </a>
                                                <div class="collapse menu-dropdown" :id="'sidebaremail'+subMenu.id">
                                                    <ul class="nav nav-sm flex-column">
                                                        <li class="nav-item" v-bind:key="child.id" v-for="(child) in subMenu.children">
                                                            <router-link :to="child.link" class="nav-link" data-key="t-mailbox">
                                                                {{ child.name }}
                                                            </router-link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <router-link style="color:#f2f2f7" :to="subMenu.link" class="nav-link" data-key="t-projects">
                                                    {{ subMenu.name }}
                                                </router-link>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="" v-else>
                                <router-link :to="menu.link" class="nav-link" data-key="t-projects">
                                    <span>
                                        <i v-if="menu.iconType==='remix'" :class="menu.icon"></i>
                                        <i v-if="menu.iconType==='boxicons'" :class="menu.icon"></i>
                                    </span>
                                    {{ menu.name }}
                                </router-link>
                            </div>
                        </li>
                    </ul>
                    <!--END top title-->
                </div>
            </ul>
        </template>
    </div>
    <!-- Sidebar -->
</template>
